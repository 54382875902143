
import { INewOrder, IProductSentencesResponse } from '@/interfaces';
import { dispatchGetProductSentencesSet, dispatchSubmitNewOrder, dispatchGetDeliveryCountries } from '@/store/main/actions';
import { Component, Vue, Prop, Watch } from 'vue-property-decorator'

import LndCountryInput from '@/components/lnd/CountryInput.vue'
import Spinner from '@/components/Spinner.vue'

@Component({
  components: {
    Spinner,
    LndCountryInput,
  },
})
export default class NewOrderModal extends Vue {
  // @ts-ignore
  @Prop({required: true, default: false}) readonly public modalShow: boolean;
  public loadingState: any = {
    page: true,
    submit: false,
  };

  public country: any = null;
  public deliveryCountries: any = [];

  public order: INewOrder = {
    products: [],
    // prePhone: '',
    // phone: '',
    country: '',
    city: '',
    delivery_address: '_',
    promocode: '',
    first_name: '',
    last_name: '',
  };
  public formError: boolean = false;
  public addressError: boolean = false;
  public latinRegex = /^[a-zA-Z\s-]*$/;
  public latinReplaceRegex = /[^a-zA-Z\s-]/g;
  public addressRegex = /^[a-zA-Z0-9\s#"'()+-:]*$/;
  public addressReplaceRegex = /[^a-zA-Z0-9\s#"'()+-:]/g;
  public timeoutId: number = 0;
  public promocodeDetails: boolean = false;
  public sentencesResponse: IProductSentencesResponse = {
    products: [],
    promocode: null,
  };


  @Watch('order.promocode')
  onChangePromocode(val: string, oldVal: string) {
    // console.debug('%c @Watch.onChangePromocode(val: %s, oldVal: %s)', 'color:gold;', val, oldVal)
    this.order.promocode = val.replace(/ /g, '')
  }

  get deliveryOption() {
    return this.deliveryCountries.find(({ country }) => country === this.country?.iso)
  }

  public get deliveryNotAvailable() {
    return !this.deliveryOption
  }

  public checkFirstLastName() {
    const first_name = this.order.first_name || ''
    const last_name = this.order.last_name || ''

    if (!this.latinRegex.test(first_name)) {
      this.order.first_name = first_name.replace(this.latinReplaceRegex, '')
    }

    if (!this.latinRegex.test(last_name)) {
      this.order.last_name = last_name.replace(this.latinReplaceRegex, '')
    }
  }

  public checkDeliveryAddress() {
    const delivery_address = this.order.delivery_address
    if (!this.addressRegex.test(delivery_address)) {
      this.order.delivery_address = delivery_address.replace(this.addressReplaceRegex, '')
    }
  }

  public checkCity() {
    const city = this.order.city
    if (!this.latinRegex.test(city)) {
      this.order.city = city.replace(this.latinReplaceRegex, '')
    }
  }

  // public checkAddress() {
  //   const address = this.order.delivery_address;
  //
  //   if (!this.addressRegex.test(address)) {
  //     this.addressError = true;
  //     // Скрыть ошибку, ведь форма была очищена от недопустимых символов
  //     clearTimeout(this.timeoutId);
  //     this.timeoutId = setTimeout(() => {
  //       this.addressError = false;
  //     }, 2000);
  //
  //     setTimeout(() => {
  //       // Очистить адрес от недопустимых символов через .3 секунды
  //       this.order.delivery_address = address.replace(this.addressReplaceRegex, '');
  //     }, 700);
  //   }
  // }

  public async mounted() {
    await this.handleUpdatePromocode();
    for (let i in this.sentencesResponse.products) {
      if (this.sentencesResponse.products[i].selected) {
        this.order.products.push(this.sentencesResponse.products[i].id);
      }
    }
    this.loadingState.page = false;
  }

  public async handleUpdatePromocode() {

    const response = await dispatchGetProductSentencesSet(
      this.$store, {
        // product_sentences_set: 'landing',
        product_sentences_set: 'dexnodeWithKey',
        promocode: this.order.promocode,
      },
    );
    if (response) {
      this.sentencesResponse = response;
    }
  }

  public validateForm() {
    if (this.deliveryNotAvailable) {
      this.formError = !this.order.country
        || !this.order.promocode
        || !this.sentencesResponse.promocode
        || this.order.products.length === 0
    } else {
      this.formError = !this.order.country
        || !this.order.city
        || !this.order.delivery_address
        || !this.order.promocode
        || !this.sentencesResponse.promocode
        || this.order.products.length === 0
    }

    return !this.formError
  }

  public async handleSubmit() {
    console.log(this.order);

    this.order.country = this.country?.iso;

    if (await this.validateForm()) {
      this.loadingState.submit = true;

      if (this.deliveryOption) {
        this.order.products.push(this.deliveryOption.delivery_product.id)
      } else {
        this.order.city = '-';
        this.order.delivery_address = '-';
      }

      const response = await dispatchSubmitNewOrder(this.$store, this.order);
      if (response.status === 200 || response.status === 201) {
        this.$emit('close-modal', response.data.id);
      } else {
        this.$toast.error(this.$t('Error').toString());
      }
      this.loadingState.submit = false;
    }
  }

  async created() {
    this.deliveryCountries = await dispatchGetDeliveryCountries(this.$store);
  }

  public validatePhone(payload) {
    this.order.phone = payload.formattedNumber ? payload.formattedNumber : '';
  }

  public closeModal() {
    this.$emit('close-modal', null);
  }

  get orderAmount() {
    let price: any = 0;

    for (let i in this.sentencesResponse.products) {
      if (this.order.products.includes(this.sentencesResponse.products[i].id)) {
        price += Number(this.sentencesResponse.products[i].price);
      }
    }

    if (this.deliveryOption) {
      price += Number(this.deliveryOption.delivery_product.price);
    }

    return price;
  }
}
